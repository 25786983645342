<template>
	<section class="settings-view">
		<h1>Settings</h1>
		<h3>Work in progress</h3>
	</section>
</template>

<script>
export default {
	name: 'Settings',
};
</script>

<style lang="scss" scoped>
	.settings-view{
		width: 100%;
		height: max-content;
		border: 3px solid $contrast-light;
	}
</style>
