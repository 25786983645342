<template>
	<section class="tracker-view">
		<h1>Initiative Tracker</h1>
		<h3>Work in progress</h3>
	</section>
</template>

<script>
export default {
	name: 'Tracker',
};
</script>

<style lang="scss" scoped>
	.tracker-view{
		width: 100%;
		height: max-content;
		border: 3px solid $contrast-light;
	}
</style>
